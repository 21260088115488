<template>
    <div class="wrapper">
        <div class="login-window">
            <div class="flex">
                <h3>{{ $t('Views.Shop.Login.Titles[0]') }}</h3>
                <div class="data-input">
                    <input
                        type="email"
                        name="email"
                        id="email"
                        v-model="email"
                        required
                    />
                    <label for="email">{{
                        $t('Views.Shop.Login.Labels[0]')
                    }}</label>
                    <div class="underline"></div>
                </div>
                <div class="data-input">
                    <input
                        type="text"
                        name="code"
                        id="code"
                        required
                        v-model="code"
                    />
                    <label for="code">{{
                        $t('Views.Shop.Login.Labels[1]')
                    }}</label>
                    <div class="underline"></div>
                </div>
            </div>
            <button @click="login">
                {{ $t('Views.Shop.Login.Buttons[0]') }}
            </button>
        </div>
        <div class="register-window">
            <div class="flex">
                <h3>{{ $t('Views.Shop.Login.Titles[1]') }}</h3>
                <div class="data-input">
                    <input
                        type="email"
                        name="email"
                        id="email"
                        required
                        v-model="email"
                    />
                    <label for="email">{{
                        $t('Views.Shop.Login.Labels[0]')
                    }}</label>
                    <div class="underline"></div>
                </div>
                <div class="data-input">
                    <input
                        type="email"
                        name="email2"
                        id="email2"
                        required
                        v-model="email2"
                    />
                    <label for="email2">{{
                        $t('Views.Shop.Login.Labels[2]')
                    }}</label>
                    <div class="underline"></div>
                </div>
                <div class="data-input">
                    <input
                        type="text"
                        name="code"
                        id="code"
                        required
                        v-model="code"
                    />
                    <label for="code">{{
                        $t('Views.Shop.Login.Labels[1]')
                    }}</label>
                    <div class="underline"></div>
                </div>
                <div class="data-input">
                    <input
                        type="text"
                        name="username"
                        id="code"
                        required
                        v-model="username"
                    />
                    <label for="username">{{
                        $t('Views.Shop.Login.Labels[3]')
                    }}</label>
                    <div class="underline"></div>
                </div>
                <div class="checkbox">
                    <input requiredd id="agb-check" type="checkbox" v-model="agb">
                    <label for="agb-check">{{ $t(`Views.Shop.AGB.Terms`) }}<router-link :to="`/${$i18n.locale}/agb`">{{
                    $t('Components.Shop.ShopFooter.ToS')
                }}</router-link>{{ $t(`Views.Shop.AGB.Terms2`) }}</label>
                </div>
            </div>
            <button @click="register">
                {{ $t('Views.Shop.Login.Buttons[1]') }}
            </button>
        </div>
    </div>
    <div class="space"></div>
</template>

<script>
import { defineComponent, onMounted, ref, computed } from '@vue/runtime-core'
import { useVueSweetAlert2 } from '@/lib/useVueSweetAlert2'
import { useChangePage } from '@/lib/useChangePage'
import { useApi } from '@/lib/useApi'
import { useStore } from 'vuex'
import Cookies from 'js-cookie'
import { useI18n } from 'vue-i18n';

export default defineComponent({
    setup() {
        const { t } = useI18n({ useScope: 'global' });
        const $swal = useVueSweetAlert2()
        const store = useStore()

        const code = computed({
            get() {
                return store.state.shop.accessCode
            },
            set(value) {
                store.commit({ type: 'updateAccessCode', accessCode: value })
            },
        })

        const email = computed({
            get() {
                return store.state.shop.email
            },
            set(value) {
                store.commit({ type: 'updateEmail', email: value })
            },
        })

        // const email = computed(() => store.state.shop.email)
        const email2 = ref('')
        const username = ref('')
        const agb = ref(false)

        // Helper Functions
        /* prettier-ignore */
        const isPlayer = async (code, email) => await useApi('isPlayer', {code: code, email: email}) //fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/player/isPlayer.php?access_code=${code}&email=${email}`).then((res) => res.json())
        /* prettier-ignore */
        const doesCodeBelongToTeam = async code => await useApi('isCodeAlreadyUsed', {code: code}) //fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/code/isCodeAlreadyUsed.php?access_code=${code}`).then((res) => res.json())
        /* prettier-ignore */
        const isCodeValid = async code => await useApi('isAccessValid', {code: code}) //fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/code/isAccessValid.php?access_code=${code}`).then((res) => res.json())
        /* prettier-ignore */
        const fetchPlayerdata = async uuid => await useApi('loadPlayerdata', {uuid: uuid}) //fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/player/loadPlayerdata.php?uuid=${uuid}`).then((res) => res.json())
        /* prettier-ignore */
        const newUser = async (code, email, username, agb) => await useApi('newPlayer', {code, email: email, username: username, agb:agb}) //fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/player/newPlayer.php?email=${email}&access_code=${code}&display_name=${username}`).then((res) => res.json())
        /* prettier-ignore */
        const newTeam = async (code, teamName) => await useApi('newTeam', {code: code, teamName: teamName}) //fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/team/newTeam.php?code=${code}&display_name=${teamName}`).then(res => res.json())

        const login = async () => {
            const uuid = await isPlayer(code.value, email.value)
            if (!uuid) return $swal.fire({ toast: true, icon: 'error', text: 'Benutzer nicht gefunden.' })

            // const playerData = await fetchPlayerdata(uuid)
            Cookies.set('uuid', uuid)
            localStorage.setItem("accessCode", code.value)
            // store.commit('setPlayer', playerData)
            store.dispatch({
                type: 'loadPlayerdata',
                uuid: uuid,
            })
            useChangePage({
                method: 'page',
                path: store.state.game.currentGame.currentPage,
            })
        }

        const register = async () => {
            /* prettier-ignore */
            if (email.value != email2.value) return $swal.fire({toast: true, icon: 'error', html: `${t("Views.Shop.Login.Errors.E-Mail")}`, timer: 1500, showConfirmButton: false})
            /* prettier-ignore */
            if (username.value == '') return $swal.fire({toast: true, icon: 'error', html: `${t("Views.Shop.Login.Errors.Username")}`, timer: 1500, showConfirmButton: false})
            /* prettier-ignore */
            if (agb.value == false) return $swal.fire({toast: true, icon: 'error', html: `${t("Views.Shop.Login.Errors.AGB")}`, timer: 1500, showConfirmButton: false})
            /* prettier-ignore */
            if (!await isCodeValid(code.value)) return $swal.fire({toast: true, icon: 'error', html: `${t("Views.Shop.Login.Errors.inValid")}`, timer: 1500, showConfirmButton: false})
            /* prettier-ignore */
            if (!await doesCodeBelongToTeam(code.value)) await $swal.fire({ html: `${t("Views.Shop.Login.Errors.Team")}`, input: 'text', preConfirm: (teamName) => newTeam(code.value, teamName)})
            /* prettier-ignore */
            const newUserUuid = await newUser(code.value, email.value, username.value, agb.value)
            /* prettier-ignore */
            if (!newUserUuid) return $swal.fire({ toast: true, icon: 'error' })

            const playerData = await fetchPlayerdata(newUserUuid)

            Cookies.set('uuid', newUserUuid)
            localStorage.setItem("accessCode", code.value)
            // store.commit('setPlayer', playerData)
            store.dispatch({
                type: 'loadPlayerdata',
                uuid: newUserUuid,
            })
            useChangePage({
                method: 'page',
                path: '/game/tutorial',
            })
        }

        onMounted(async () => {
            if (!Cookies.get('uuid')) return
            let uuid = Cookies.get('uuid')
            Cookies.set('uuid', uuid)
            // const playerData = await fetchPlayerdata(uuid)
            // store.commit('setPlayer', playerData)
            store.dispatch({
                type: 'loadPlayerdata',
                uuid: uuid,
            })
            useChangePage({
                method: 'page',
                path: store.state.game.currentGame.currentPage,
            })
        })

        return { agb, code, email, email2, username, login, register }
    },
})
</script>

<style scoped>
#app {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
}
h3 {
    font-family: Special Elite;
    font-size: 20px;
}
.wrapper {
    display: flex;
    margin: 20px;
    justify-content: center;
    gap: 5vw;
    margin-top: 5%;
    margin-bottom: 5%;
}
.flex {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 30vw;
    min-width: 200px;
    max-width: 500px;
}
.data-input {
    position: relative;
    width: 100%;
    height: 40px;
}
.data-input input {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.562);
    outline: none;
    font-size: 17px;
}
.data-input label {
    position: absolute;
    bottom: 10px;
    left: 0px;
    width: 100%;
    text-align: left;
    pointer-events: none;
    font-size: 17px;
    transition: all 0.3s ease;
}
.data-input input:focus ~ label,
.data-input input:valid ~ label {
    transform: translateY(-30px);
    font-size: 15px;
    color: #1b1b1b;
}
.register-window,
.login-window {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.register-window button {
    width: 100%;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 17px;
    border: none;
    border-radius: 0.5em;
    box-sizing: border-box;
    font-weight: 300;
    color: #ffffff;
    background-color: #2c6060;
    text-align: center;
    transition: all 0.2s;
}
.register-window button:hover {
    background-color: #245050;
}
.login-window button {
    width: 100%;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 17px;
    border: none;
    border-radius: 0.5em;
    box-sizing: border-box;
    font-weight: 300;
    color: #ffffff;
    background-color: #2c6060;
    text-align: center;
    transition: all 0.2s;
}
.login-window button:hover {
    background-color: #245050;
}
.underline {
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 2px;
    width: 100%;
}
.underline:before {
    position: absolute;
    left: 0;
    content: '';
    height: 100%;
    width: 100%;
    background: #2c6060;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}
.data-input input:focus:valid ~ .underline:before,
.data-input input:valid ~ .underline:before {
    transform: scaleX(1);
}
.data-input input:focus:invalid ~ .underline:before {
    background: rgb(224, 70, 70);
    transform: scaleX(1);
}
@media screen and (max-width: 750px) {
    .wrapper {
        flex-direction: column;
    }
    .register-window,
    .login-window {
        margin-left: 15%;
        margin-right: 15%;
    }
    .flex {
        width: 100%;
        min-width: 200px;
    }
}
@media screen and (max-width: 400px) {
    .register-window,
    .login-window {
        margin-left: 10%;
        margin-right: 10%;
    }
}
.checkbox label {
    font-size: 17px;
}
.checkbox label a{
    font-size: 17px;
    color: #2c3e50;
}
.checkbox label a:hover{
    font-size: 17px;
    color: #2c6060;
}
</style>
