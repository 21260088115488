<template>
    <div class="container">
        <h1>Datenschutz</h1>
        <h3>Herzlich Willkommen auf unserer Webseite!</h3>
        <p>Wir legen größten Wert auf den Schutz Ihrer Daten und die Wahrung Ihrer Privatsphäre. Nachstehend informieren wir Sie deshalb über die Erhebung und Verwendung persönlicher Daten bei Nutzung unserer Webseite.</p>
        <p>Unser Datenschutzbeauftragter gemäß Art. 35 DSGVO: datenschutz@mystery-house.de</p>
        <h3>Anonyme Datenerhebung</h3>
        <p>Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Wir speichern in diesem Zusammenhang keinerlei persönliche Daten. Um unser Angebot zu verbessern, werten wir lediglich statistische Daten aus, die keinen Rückschluss auf Ihre Person erlauben.</p>
        <h3>Erhebung und Verarbeitung bei Nutzung des Kontaktformulars</h3>
        <p>Bei Nutzung des Kontaktformulars erheben wir personenbezogene Daten (Einzelangaben über persönliche oder sachliche Verhältnisse einer bestimmten oder bestimmbaren natürlichen Person) nur in dem von Ihnen zur Verfügung gestellten Umfang. Ihre E-Mail-Adresse nutzen wir nur zur Bearbeitung Ihrer Anfrage. Ihre Daten werden anschließend gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.</p>
        <h3>Erhebung, Verarbeitung und Nutzung personenbezogener Daten</h3>
        <p>Wir erheben personenbezogene Daten (Einzelangaben über persönliche oder sachliche Verhältnisse einer bestimmten oder bestimmbaren natürlichen Person) nur in dem von Ihnen zur Verfügung gestellten Umfang.<br>Die Verarbeitung und Nutzung Ihrer personenbezogenen Daten erfolgt zur Erfüllung und Abwicklung Ihrer Bestellung sowie zur Bearbeitung Ihrer Anfragen.</p>
        <p>Nach vollständiger Vertragsabwicklung werden alle personenbezogenen Daten zunächst unter Berücksichtigung steuer- und handelsrechtlicher Aufbewahrungsfristen gespeichert und dann nach Fristablauf gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.</p>
        <h3>Verwendung der E-Mail-Adresse für die Zusendung von Direktwerbung</h3>
        <p>Soweit Sie nicht widersprochen haben, nutzen wir Ihre E-Mail-Adresse, die wir im Rahmen des Verkaufes einer Ware oder Dienstleistung erhalten haben für die elektronische Übersendung von Werbung für eigene Waren oder Dienstleistungen, die denen ähnlich sind, die Sie bereits bei uns erworben haben. Sie können dieser Verwendung Ihrer E-Mail-Adresse jederzeit durch eine Mitteilung an uns widersprechen. Die Kontaktdaten für die Ausübung des Widerspruchs finden Sie im Impressum. Sie können auch den dafür vorgesehenen Link in der Werbeemail nutzen. Hierfür entstehen keine anderen als die Übermittlungskosten nach den Basistarifen.</p>
        <h3>Weitergabe personenbezogener Daten</h3>
        <p>Eine Weitergabe Ihrer Daten an Dritte ohne Ihre ausdrückliche Einwilligung erfolgt nicht. Ausgenommen hiervon sind lediglich unsere Dienstleistungspartner, die wir zur Abwicklung des Vertragsverhältnisses benötigen. In diesen Fällen beachten wir strikt die Vorgaben des Bundesdatenschutzgesetzes. Der Umfang der Datenübermittlung beschränkt sich auf ein Mindestmaß.</p>
        <h3>Cookies</h3>
        <p>Unsere Internetseiten verwenden an mehreren Stellen so genannte Cookies. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert. Sie dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Des Weiteren ermöglichen Cookies unseren Systemen, Ihren Browser zu erkennen und Ihnen Services anzubieten. Cookies enthalten keine personenbezogenen Daten.</p>
        <h3>Auskunft, Berichtigung, Sperrung und Löschung von Daten</h3>
        <p>Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten Daten sowie das Recht auf Berichtigung, Löschung bzw. Sperrung. Kontaktieren Sie uns auf Wunsch. Die Kontaktdaten finden Sie in unserem Impressum.</p>
    </div>
</template>

<script>
export default {}
</script>

<style scoped>
.container{
    display: block;
    width: 80%;
    max-width: 800px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
}
h1{
    margin-top: 30px;
    margin-bottom: 30px;
}
h3{ 
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 30px;
}
p{
    font-size: 20px;
    margin: 14px;
    text-align: center;
}
</style>
