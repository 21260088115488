<template>
    <font-awesome-icon
        icon="star"
        v-for="index in 5"
        :key="index"
        :class="rating >= index ? 'star-btn active' : 'star-btn not-active'"
        @click="setRating(index)"
    />
    <input type="hidden" name="stars" id="stars" v-model="rating" />
</template>

<script setup>
import { ref } from 'vue'
import { useEmitter } from '@/lib/useEmitter'

const emitter = useEmitter()
const rating = ref(5)

const setRating = function (value) {
    rating.value = value
    emitter.emit('ratingUpdate', value)
}
</script>

<style scoped>
.star-btn {
    cursor: pointer;
}
.active {
    color: gold;
}
.not-active {
    color: grey;
}
</style>
